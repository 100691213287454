<template>
  <div class="product-page">
    <div class="left-text">
      <div
        class="left-text__back"
        :style="{
          height: textBackgroundHeight + 'px',
        }"
      >
        <div
          class="left-text__parallax"
          :style="{
            height: 100 * offsets.length + 'vh',
            transform: parallaxTransform2,
          }"
        ></div>
      </div>
      <div class="left-text__text"></div>
    </div>
    <div
      class="parallax-bg"
      :style="{
        height: 100 * offsets.length + '%',
        transform: parallaxTransform,
      }"
    ></div>
    <div class="sections-next" @click="scrollToSection(activeSection + 1)" v-if="activeSection != offsets.length - 1" :style="{
        marginBottom: textHeight + 'px',
      }">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M0.788574 0.884466L5.50011 5.86523L10.2117 0.884466" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div class="sections-prev" @click="scrollToSection(activeSection - 1)" v-if="activeSection != 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M10.2114 6.78839L5.49989 1.80762L0.788349 6.78839" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div
      class="sections-menu"
      :style="{
        marginTop: -(textHeight - headerHeight) / 2 + 'px',
      }"
    >
      <span
        class="menu-point"
        v-bind:class="{ active: activeSection == index }"
        v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets"
        v-bind:key="index"
      >
      </span>
    </div>
    <div ref="packOverlay" class="cigarettes-pack-page cigarettes-pack-page-3" :class="{'mobile-rotate': mobileRotate}">
      <canvas class="canvas-3d" :class="{'canvas-3d_hidden': !allAssetsLoaded}" ref="canvas3D"></canvas>
      <div
        v-if="assetLoadFailed"
        class="cigarettes-pack-page__placeholder cigarettes-pack-page__placeholder_3"
        :class="{'cigarettes-pack-page__placeholder_hidden': !placeholderVisible}"
      ></div>
      <div class="canvas-3d__loader" :class="{'canvas-3d__loader_hidden': !modelPreloaderVisible}">
        <img src="/frontend/assets/i/loader.svg" alt="">
      </div>
      <transition name="fade">
        <div class="cigarettes-pack-page__360" v-if="show360"></div>
      </transition>
    </div>
    <div
      id="fp"
      :style="{
        height: containerHeight + 'px',
      }"
    >
      <section
        class="fullpage"
        :class="{
          active: animateIndex === index,
          transition: slideTransitionRunning,
          leaving: animateLeaveIndex === index,
          'has-button': nextButton.url && offsets.length === index + 1
        }"
        v-for="(item, key, index) in $slots"
        :key="index"
      >
        <div>
          <transition name="slide-left">
            <div
              v-show="animateIndex === index"
              class="animate-block"
              ref="anim"
            >
              <slot :name="key"></slot>
            </div>
          </transition>
          <div v-if="nextButton.url && offsets.length == index + 1" class="_g-hidden-tablet">
            <div class="root-more _product">
              <div class="container">
                <transition name="slide-right">
                  <div v-show="animateIndex == index">
                    <router-link :to="{ path: nextButton.url }" class="button button__lg button__blue button__arrow" v-html="nextButton.text"></router-link>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { VctrApi } from "./../scripts-3d/vectaryToThreeJs";
import productMixin from "../mixins/productMixin";
import threeJsMixin from "../mixins/threeJsMixin";

export default {
  name: 'ProductPage',
  mixins: [productMixin, threeJsMixin],

  data() {
    const zoomModifier = .9814;
    return {
      modelUrl: '/frontend/assets/3D/mevius-model-3/4319a367-6a03-4180-a217-db6e986a88c5.gltf',
      states: [
        {
          "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
          "position": [-0.0012263118504406748, 0.07208718114887779, 0.3918454071945966],
          "zoom": 1.25 * zoomModifier
        }, {
          "target": [-0.0004406095547023481, 0.07877903385319294, 0.0029180448538671795],
          "position": [-0.24981076488049378, 0.1931067279690376, 0.10744210771662978],
          "zoom": 1.15 * zoomModifier
        }, {
          "target": [-0.0004406095547023481, 0.05977903385319294, -0.001918044853867179],
          "position": [0, 0.0769840106627164, 0.33734226558641245],
          "zoom": 1.1 * zoomModifier
        }, {
          "target": [-0.0004406095547023481, 0.06877903385319294, -0.001918044853867179],
          "position": [-0.1999359750673202, 0.0769840106627164, 0.33734226558641245],
          "zoom": 1.2 * zoomModifier
        }, {
          "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
          "position": [-0.1999359750673202, 0.0769840106627164, 0.33734226558641245],
          "zoom": 1.25 * zoomModifier
        }
      ],
      materialsCutData: [
        {
          name: 'вкладыш',
          axis: 2, // z
          threshold: 35,
          direction: 1
        },
        {
          name: 'Cylinder_4_3',
          axis: 1, // y
          threshold: 55,
          direction: 1
        },
        {
          name: 'сигареты',
          axis: 1, // y
          threshold: 35,
          direction: 1
        }
      ],
      materialsCutTimeFractionRange: { // на какой промежуток времени скрывать
        'forwards': [0, .39],
        'backwards': [.62, 1]
      },
      discardOverlappingParts: false, // скрывать ли части 3D-элементов, которые пересекаются с крышкой в момент ее открытия
      materialsToClone: ['Cylinder_4_3'],
      //materialsToHide: ['крышка1', 'крышка_1', 'тело'] // полностью скрыть объект, нужно только для тестирования
    }
  },
  mounted() {
    this.handleComponentMounted();
  },
  methods: {
    async animatePack() {
      let state = this.states[this.activeSection];

      // на 4 и 5 экранах открываем пачку, если она закрыта и вытаскиваем сигарету
      if (this.activeSection > 0 && this.activeSection < 4) {
        this.activateMobileRotate();
        this.animatePackPosition(state, 1500);
        if (!this.isUp) {
          this.isClosed = false;
          this.isUp = true;
          await this.switchPack(true);
          setTimeout(() => { this.moveCigarette(true)}, 1500);
        }
        return;
      }

      // анимация пачки
      if (!this.isClosed) {
        this.deactivateMobileRotate();
        this.isClosed = true;
        this.isUp = false;
        //await this.moveCigarette(false);
        this.animatePackPosition(state);
        this.switchPack(false);
      } else {
        this.animatePackPosition(state, 1500);
      }
    },
    async moveCigarette(isOpen) {
      const cigaretteObjectName = 'Cylinder_4_3';
      const currentPosition = await this.vectaryApi.getPosition(cigaretteObjectName);
      const yPosition = isOpen ? 15.775012493133541 : 1.2912301079029177;

      VctrApi.Utils.animate(
        'moveCigarette',
        500,
        "easeInOutQuad",
        async (timeFraction) => {
          if (!this.vectaryApi) return; // if user moved to another product page during animation
          const position = VctrApi.Utils.lerp(currentPosition, [currentPosition[0], yPosition, currentPosition[2]], timeFraction);
          await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, position);
        },
        this.handleAnimationFinish
      );
    },
    async switchPack(isOpen) {
      const capObjectName = 'крышка';
      const bushObjectName = 'вкладыш';
      const allCigObjectName = 'сигареты';
      const cigaretteObjectName = 'Cylinder_4_3';
      const currentRotation = await this.vectaryApi.getRotation(capObjectName);
      const yPosition = 41.93366541345034;
      const yPositionCig = 34.526337542633616;
      const yPositionSingleCig = 1.2912301079029177;

      const capDuration = 700;
      const cigDuration = 400;
      const cigDelay = isOpen ? 100 : 0;
      const capDelay = isOpen ? 0 : 50;

      const newPosition = await this.vectaryApi.getPosition(bushObjectName);
      const newAllCigPosition = await this.vectaryApi.getPosition(allCigObjectName);
      const initMoveCigPosition = await this.vectaryApi.getPosition(cigaretteObjectName);

      await this.vectaryApi.setPositionAbsolute(bushObjectName, [newPosition[0], yPosition, newPosition[2]]);
      await this.vectaryApi.setPositionAbsolute(allCigObjectName, [newAllCigPosition[0], yPositionCig, newAllCigPosition[2]]);
      if (isOpen) {
        await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, [initMoveCigPosition[0], yPositionSingleCig, initMoveCigPosition[2]]);
      }

      setTimeout(async () => {
        VctrApi.Utils.animate(
          'switchPack',
          cigDuration,
          "easeInOutQuad",
          async (timeFraction) => {
            if (!this.vectaryApi) return; // if user moved to another product page during animation
            const newMoveCigPosition = await this.vectaryApi.getPosition(cigaretteObjectName);
            const singleCigPosition = VctrApi.Utils.lerp(newMoveCigPosition, [newMoveCigPosition[0], yPositionSingleCig, newMoveCigPosition[2]], timeFraction);
            await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, singleCigPosition);
          },
          this.handleAnimationFinish
        );
      }, cigDelay);

      setTimeout(async () => {
        VctrApi.Utils.animate(
          'switchPack',
          capDuration,
          "easeInOutQuad",
          async (timeFraction) => {
            if (!this.vectaryApi) return; // if user moved to another product page during animation
            if (this.materialsCutData) {
              this.replaceFragmentShaders(timeFraction, isOpen);
            }
            const rotation = VctrApi.Utils.lerp(currentRotation, [isOpen ? -45 : 44.370000000000005, currentRotation[1], currentRotation[2]], timeFraction);
            await this.vectaryApi.setRotationAbsolute(capObjectName, rotation);
          },
          this.handleAnimationFinish
        );
      }, capDelay)
    },
  }
};
</script>
