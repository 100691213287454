var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-input",class:{
    'text-input_focused': _vm.focused,
    'text-input_filled': _vm.filled,
    'text-input_invalid': _vm.error,
    'text-input_date': _vm.type === 'date',
    'text-input_textarea': _vm.inputTag === 'textarea'
  }},[_c('label',{staticClass:"text-input__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c(_vm.inputTag,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.type === 'tel' ? '+7 (###) ###-##-##' : null),expression:"type === 'tel' ? '+7 (###) ###-##-##' : null"}],ref:"input",tag:"component",staticClass:"text-input__field",class:_vm.appendClasses,attrs:{"value":_vm.value,"type":_vm.type,"name":_vm.name,"max":_vm.max,"tabindex":_vm.tabindex,"autocomplete":_vm.autocomplete,"placeholder":(_vm.type === 'tel' && _vm.focused) ? '+7 (999) 999-99-99' : null,"rows":_vm.inputTag === 'textarea' ? _vm.rows : null,"disabled":_vm.disabled},on:{"focus":_vm.focus,"blur":_vm.blur,"change":_vm.emitChange,"input":_vm.emitChange}}),_vm._t("errors")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }