import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { VctrApi } from "../scripts-3d/vectaryToThreeJs";
import Ticker from "../scripts-3d/Ticker.js";
import Swiper from "../scripts-3d/Swiper.js";
import Rotator from "../scripts-3d/Rotator.js";
import getShaders from "../scripts-3d/shaders.js";

export default {
  data() {
    return {
      width: null,
      height: null,
      cameraWidth: null,
      cameraHeight: null,
      scene: null,
      camera: null,
      renderer: null,
      composer: null,
      renderPass: null,
      canvas: null,
      canvasHeight: null,
      packOverlay: null,
      ticker: null,
      applyViewStateOnTickRequested: false,
      renderRequested: true,
      initialState: null,
      environmentLoaded :false,
      environmentLoadFailed :false,
      modelLoaded: false, // to show canvas
      modelLoadFailed: false, // to add placeholder to the DOM
      lightMapLoaded: false,
      lightMapLoadFailed: false,
      innerTextureLoadResponceReceived: false, // inner texture load can succeed or fail, any result is ok
      placeholderVisible: false, // to show placeholder
      envMap: null,
      lightMap: null,
      innerTexture: null,
      lightMapUrl: '/frontend/assets/3D/light-maps/light-map.png',
      environmentUrl: '/frontend/assets/3D/environments/snowy_forest_path_256k.hdr',
      rotator: null,
      currentViewState: null,
      vertexShader: null,
      fragmentShader: null,
      fragmentShaderCut: null,
      cacheId: 0,
      materialsData: {}
    }
  },
  methods: {
    async handleComponentMounted() {
      this.calculateAngleAndDistanceForStates();

      // define scene
      this.scene = new THREE.Scene();
      this.canvas = this.$refs.canvas3D;
      this.packOverlay = this.$refs.packOverlay;

      // set initial state for camera
      this.initialState = this.states[0];

      // define renderer
      this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, alpha: true });
      //this.renderer.setSize(this.width, this.height);
      this.renderer.setPixelRatio(window.devicePixelRatio);

      // set objects for model rotation and animation
      this.ticker = new Ticker();
      const swiper = new Swiper(this.packOverlay, false);
      this.rotator = new Rotator();
      swiper.addClient(this.rotator);
      this.ticker.addClient(this.rotator);
      this.ticker.addClient(this);
      this.rotator.addClient(this);

      this.width = this.canvas.offsetWidth;
      this.height = this.canvas.offsetHeight;
      this.cameraWidth = this.width;
      this.cameraHeight = this.height;

      // define camera
      this.camera = new THREE.PerspectiveCamera(
        35,
        this.cameraWidth / this.cameraHeight,
        .01,
        1000
      );

      this.camera.position.set(this.initialState.position[0], this.initialState.position[1], this.initialState.position[2]);
      this.camera.lookAt(new THREE.Vector3(this.initialState.target[0], this.initialState.target[1], this.initialState.target[2]));
      this.camera.zoom = this.initialState.zoom;

      // set coloring parameters
      this.renderer.outputEncoding = THREE.GammaEncoding;
      this.renderer.gammaFactor = 1.5;
      this.renderer.toneMapping = THREE.CustomToneMapping;
      this.renderer.toneMappingExposure = 10;

      // set shaders
      const shaders = getShaders();
      for (let p in shaders) {
        THREE.ShaderChunk[p] = shaders[p];
      }

      // Set CustomToneMapping to Uncharted2
      // source: http://filmicworlds.com/blog/filmic-tonemapping-operators/
      THREE.ShaderChunk.tonemapping_pars_fragment = THREE.ShaderChunk.tonemapping_pars_fragment.replace(
        'vec3 CustomToneMapping( vec3 color ) { return color; }',
        `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
        float toneMappingWhitePoint = 1.0;
        vec3 CustomToneMapping( vec3 color ) {
          color *= toneMappingExposure;
          return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
        }`
      );

      // pass necessary parameters to the API
      this.vectaryApi.setCamera(this.camera);
      this.vectaryApi.setCurrentViewState(this.initialState);

      // load external assets
      this.loadLightMap();
      this.loadInnerTexture();
      this.loadModel();
      this.loadEnvironment();

      window.addEventListener('resize', this.handleWindowResize.bind(this));
    },
    calculateAngleAndDistanceForStates() {
      this.states.forEach(state => {
        VctrApi.Utils.recalculateViewStateAngleAndDistance(state);
      })
    },
    calculateCanvasSize(containerWidth, containerHeight) {
      const containerAspectRatio = containerWidth / containerHeight;
      let canvasHeight, canvasWidth;
      const canvasAspectRatio = .8;

      if (containerAspectRatio >= canvasAspectRatio) {
        canvasHeight = containerHeight;
        canvasWidth = canvasHeight * canvasAspectRatio;
      } else {
        canvasWidth = containerWidth;
        canvasHeight = canvasWidth / canvasAspectRatio;
      }
      return { width: canvasWidth, height: canvasHeight }
    },
    handleWindowResize() {
      this.width = this.packOverlay.offsetWidth;
      this.height = this.packOverlay.offsetHeight;
    },
    receiveNotification(value, sender) {
      switch (sender) {
        case 'ticker':
          this.animate();
          break;
        case 'rotator':
          this.rotateCameraAroundModel(value);
          break;
      }
    },
    loadEnvironment() {
      const loader = new RGBELoader();
      // Load the HDR file
      loader.load(this.environmentUrl, (texture) => {
          // Create a PMREM generator for the environment map
          const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
          this.envMap = pmremGenerator.fromEquirectangular(texture).texture;
          // Set the environment map for the scene
          // Clean up the texture
          texture.dispose();
          this.handleEnvironmentLoad();
        },
        undefined,
        this.handleEnvironmentLoadError
      );
    },
    handleEnvironmentLoad() {
      this.environmentLoaded = true;
      this.handleAssetLoad();
    },
    handleEnvironmentLoadError() {
      this.environmentLoadFailed = true;
    },
    loadLightMap() {
      const textureLoader = new THREE.TextureLoader();
      this.lightMap = textureLoader.load(
        this.lightMapUrl,
        this.handleLightMapLoad,
        null,
        this.handleLightMapLoadError
      );
    },
    handleLightMapLoad() {
      this.lightMap.flipY = false;
      this.lightMap.encoding = 3001;
      this.lightMap.environmentBaked = true;
      this.lightMapLoaded = true;
      this.handleAssetLoad();
    },
    handleLightMapLoadError() {
      this.lightMapLoadFailed = true;
      setTimeout(this.showPlaceholder.bind(this), 50);
    },
    loadInnerTexture() {
      if (!this.innerTextureUrl) {
        this.handleInnerTextureLoad(); // if no texture presented, just mimic texture load
        return;
      }
      const textureLoader = new THREE.TextureLoader();
      this.innerTexture = textureLoader.load(
        this.innerTextureUrl,
        this.handleInnerTextureLoad,
        null,
        this.handleInnerTextureLoadError 
      );
    },
    handleInnerTextureLoad() {
      this.innerTextureLoadResponceReceived = true;
      this.handleAssetLoad();
    },
    handleInnerTextureLoadError() {
      this.innerTextureLoadResponceReceived = true;
    },
    loadModel() {
      const loader = new GLTFLoader();
      loader.load(
        this.modelUrl,
        (gltf) => {
          this.scene.add(gltf.scene); // Add the loaded model to the scene
          this.vectaryApi.setModel(gltf.scene);

          // Optionally, set the model's position or scale here
          gltf.scene.position.set(0, 0, 0); // Center the model
          gltf.scene.scale.set(1, 1, 1); // Adjust scale if necessary
          this.handleModelLoad();
        },
        null,
        this.handleModelLoadError
      );
    },
    handleModelLoad() {
      this.modelLoaded = true;
      this.handleAssetLoad();
    },
    // launched after both model and environment are loaded
    handleAssetLoad() {
      if (this.allAssetsLoaded) {
        this.adjustMaterial();
        this.handleWindowResize();
        this.resizeEvent();
        this.ticker.start();
      }
    },
    cloneMeshes() {
      /*
      клонировать меш для создания внутренней стороны пачки
      */
      if (!this.meshesToClone) return;
      this.meshesToClone.forEach( name => {
        const mesh = this.vectaryApi.getModelChildByName(name);
        const clonedMesh = mesh.clone();
        const clonedMaterial = mesh.material.clone();
        const clonedMap = mesh.material.map.clone();
        clonedMap.image = this.innerTexture.image;
        clonedMaterial.map = clonedMap;
        clonedMesh.material = clonedMaterial;

        clonedMesh.position.copy(mesh.position);
        clonedMesh.rotation.copy(mesh.rotation);
        clonedMesh.scale.copy(mesh.scale);
        clonedMesh.name = mesh.name + '_clone';

        mesh.parent.add(clonedMesh);
      });
    },
    cloneMaterials() {
      /*
        если несколько объектов используют один и тот же материал,
        нужно создать уникальный клон этого материала,
        чтобы применять к нему отдельные преобразования
      */
      if (!this.materialsToClone) return;
      this.materialsToClone.forEach( name => {
        const mesh = this.vectaryApi.getModelChildByName(name);
        const clonedMaterial = mesh.material.clone();
        mesh.material = clonedMaterial;
      });
    },
    setShaders() {
      this.vertexShader = `
        #define STANDARD
        varying vec3 vViewPosition;
        varying vec3 vLocalPosition;
        #ifdef USE_TRANSMISSION
          varying vec3 vWorldPosition;
        #endif
        varying vec3 vWorldPosition2;
        #include <common>
        #include <uv_pars_vertex>
        #include <uv2_pars_vertex>
        #include <displacementmap_pars_vertex>
        #include <color_pars_vertex>
        #include <fog_pars_vertex>
        #include <normal_pars_vertex>
        #include <morphtarget_pars_vertex>
        #include <skinning_pars_vertex>
        #include <shadowmap_pars_vertex>
        #include <logdepthbuf_pars_vertex>
        #include <clipping_planes_pars_vertex>
        void main() {
          vLocalPosition = position;
          #include <uv_vertex>
          #include <uv2_vertex>
          #include <color_vertex>
          #include <beginnormal_vertex>
          #include <morphnormal_vertex>
          #include <skinbase_vertex>
          #include <skinnormal_vertex>
          #include <defaultnormal_vertex>
          #include <normal_vertex>
          #include <begin_vertex>
          #include <morphtarget_vertex>
          #include <skinning_vertex>
          #include <displacementmap_vertex>
          #include <project_vertex>
          #include <logdepthbuf_vertex>
          #include <clipping_planes_vertex>
          vViewPosition = - mvPosition.xyz;
          #include <worldpos_vertex>
          #include <shadowmap_vertex>
          #include <fog_vertex>
        #ifdef USE_TRANSMISSION
          vWorldPosition = worldPosition.xyz;
        #endif
        }
      `;

      const materialShaderPart1 = [
        `#define ENV_MAP_DIFFUSE_MULTIPLIER 0.0`,
        `#define AOMAP_UV_CHANNEL vUv2`,
        `#define PHYSICAL`,
        `uniform vec3 diffuse;`,
        `uniform vec3 emissive;`,
        `uniform float roughness;`,
        `uniform float metalness;`,
        `uniform float opacity;`,
        `uniform float uCutAxis;`, // Uniform for axis
        `uniform float uCutThreshold;`, // Uniform for threshold
        `uniform float uCutDirection;`, // Uniform for direction
        `#ifndef STANDARD`,
        `    uniform float clearCoat;`,
        `    uniform float clearCoatRoughness;`,
        `#endif`,
        `varying vec3 vViewPosition;`,
        `varying vec3 vLocalPosition;`,
                    `#ifndef FLAT_SHADED`,
                    `    varying vec3 vNormal;`,
        `    #ifdef USE_TANGENT`,
        `        varying vec3 vTangent;`,
        `        varying vecabcd3 vBitangent;`,
        `    #endif`,
        `#endif`,
        `#include <common>`,
        `#include <packing>`,
        `#include <dithering_pars_fragment>`,
        `#include <color_pars_fragment>`,
        `#include <uv_pars_fragment>`,
        `#include <uv2_pars_fragment>`,
        `#include <map_pars_fragment>`,
        `#include <alphamap_pars_fragment>`,
        `#include <aomap_pars_fragment>`,
        `#include <lightmap_pars_fragment>`,
        `#include <emissivemap_pars_fragment>`,
        `#include <bsdfs>`,
        `#include <cube_uv_reflection_fragment>`,
        `#include <envmap_common_pars_fragment>`,
        `#include <envmap_physical_pars_fragment>`,
        `#include <fog_pars_fragment>`,
        `#include <lights_pars_begin>`,
        `#include <lights_physical_pars_fragment>`,
        `#include <shadowmap_pars_fragment>`,
        `#include <bumpmap_pars_fragment>`,
        `#include <normalmap_pars_fragment>`,
        `#include <roughnessmap_pars_fragment>`,
        `#include <metalnessmap_pars_fragment>`,
        `#include <logdepthbuf_pars_fragment>`,
        `#include <clipping_planes_pars_fragment>`,
        `void main() {`,
        `    #include <clipping_planes_fragment>`
      ].join('\n');

      const materialShaderDiscard = [
        `if (uCutAxis == 0.0) {`,
        `  if (uCutDirection > 0.0) { `,
        `    if (vLocalPosition.x > uCutThreshold) {`,
        `      discard;`,
        `    }`,
        `  } else {`,
        `    if (vLocalPosition.x < uCutThreshold) {`,
        `      discard;`,
        `    }`,
        `  }`,
        `} else if (uCutAxis == 1.0) {`,
        `  if (uCutDirection > 0.0) { `,
        `    if (vLocalPosition.y > uCutThreshold) {`,
        `      discard;`,
        `    }`,
        `  } else {`,
        `    if (vLocalPosition.y < uCutThreshold) {`,
        `      discard;`,
        `    }`,
        `  }`,
        `} else if (uCutAxis == 2.0) {`,
        `  if (uCutDirection > 0.0) { `,
        `    if (vLocalPosition.z > uCutThreshold) {`,
        `      discard;`,
        `    }`,
        `  } else {`,
        `    if (vLocalPosition.z < uCutThreshold) {`,
        `      discard;`,
        `    }`,
        `  }`,
        `}`
      ].join('\n');

      const materialShaderPart2 = [
        `    vec4 diffuseColor = vec4( diffuse, opacity );`,
        `    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ),
        vec3( 0.0 ),
        vec3( 0.0 ),
        vec3( 0.0 ) );`,
        `    vec3 totalEmissiveRadiance = emissive;`,
        `    #include <logdepthbuf_fragment>`,
        `    #include <map_fragment>`,
        `    #include <color_fragment>`,
        `    vec3 rawDiffuseColor = diffuseColor.rgb;`,
        `    #include <alphamap_fragment>`,
        `    #include <alphatest_fragment>`,
        `    #include <roughnessmap_fragment>`,
        `    #include <metalnessmap_fragment>`,
        `    #include <normal_fragment_begin>`,
        `    #include <normal_fragment_maps>`,
        `    #include <emissivemap_fragment>`,
        `    #include <lights_physical_fragment>`,
        `    #include <lights_fragment_begin>`,
        `    #include <lights_fragment_maps>`,
        `    #include <lights_fragment_end>`,
        `    #include <aomap_fragment>`,
        `    vec3 outgoingLight = reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;`,
        `    #ifndef USE_LIGHTMAP`,
        `        outgoingLight += reflectedLight.directDiffuse;`,
                    `    #endif`,
        `    gl_FragColor = vec4( outgoingLight, diffuseColor.a );`,
        `    #include <tonemapping_fragment>`,
        `    #include <encodings_fragment>`,
        `    #include <fog_fragment>`,
        `    #include <premultiplied_alpha_fragment>`,
        `    #include <dithering_fragment>`,
        `}`
      ].join('\n');

      this.fragmentShader = materialShaderPart1 + materialShaderPart2;
      this.fragmentShaderCut = materialShaderPart1 + materialShaderDiscard + materialShaderPart2;
    },
    adjustMaterial() {
      this.cloneMeshes();
      this.cloneMaterials();
      this.setShaders();
      this.createPlane();

      this.vectaryApi.model.traverse(mesh => {
        if (mesh && mesh.material) {
          const material = mesh.material;

          if (material.map) {
            material.map.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
            material.map.needsUpdate = true;
          }

          // hide mesh if necessary
          if (this.meshesToHide && this.meshesToHide.some( item => item === mesh.name )) {
            mesh.visible = false;
          }

          // provide correct depth sorting
          material.depthWrite = true;
          // provide lightMap
          material.lightMap = this.lightMap;
          // apply envMap
          material.envMap = this.envMap,
          material.envMapIntensity = .92;

          // add glossiness
          if (mesh.name === 'вкладыш') {
            material.roughness = .15;
            material.envMapIntensity = 1.5;   
          }

          if (mesh.name === 'тело_осн_1') {
            material.side = THREE.FrontSide;
          }

          if (mesh.name === 'тело_осн_1_clone') {
            material.side = THREE.BackSide;
            material.metalness = 0;
            material.roughness = 1;
            // create black square and apply it to emissive map
            let texture = this.createTexture(0, 0, 0); 
            material.emissiveMap = texture;
            material.roughnessMap = null;
          }

          const cutData = this.materialsCutData?.find( item => mesh.name === item.name );

          // set cache keys
          if (cutData) {
            this.materialsData[mesh.name] = {
              cacheKeyMain: 'cacheKey' + (this.cacheId ++),
              cacheKeySecondary: 'cacheKey' + (this.cacheId ++)
            };
          } else {
            // set shaders for materials that don't change
            material.onBeforeCompile = e => {

              e.vertexShader = this.vertexShader;
              e.fragmentShader = this.fragmentShader;

              // set dummy uniforms
              const axisValue = 0;
              const thresholdValue = 0;
              const directionValue = 1;

              if (!e.uniforms.uCutAxis) {
                e.uniforms.uCutAxis = { value: axisValue }
              } else {
                e.uniforms.uCutAxis.value = axisValue;
              }

              if (!e.uniforms.uCutThreshold) {
                e.uniforms.uCutThreshold = { value: thresholdValue }
              } else {
                e.uniforms.uCutThreshold.value = thresholdValue;
              }

              if (!e.uniforms.uCutDirection) {
                e.uniforms.uCutDirection = { value: directionValue }
              } else {
                e.uniforms.uCutDirection.value = directionValue;
              }
            }
            material.needsUpdate = true;
          }
        }
      });

      // set states for materials that change
      this.setFragmentShaders('main');
      setTimeout(() => {
      this.setFragmentShaders('secondary');
      }, 100);
    },
    replaceFragmentShaders(timeFraction, isOpen) {
      const range = this.materialsCutTimeFractionRange[ isOpen ? 'forwards' : 'backwards' ];
      if ((timeFraction >= range[0]) && (timeFraction <= range[1])) {
        if (!this.discardOverlappingParts) {
          this.discardOverlappingParts = true;
          this.setFragmentShaders('secondary');
        }
      } else {
        if (this.discardOverlappingParts) {
          this.discardOverlappingParts = false;
          this.setFragmentShaders('main');
        }
      }
    },
    setFragmentShaders(mode) {
      this.vectaryApi.model.traverse(mesh => {
        if (mesh && mesh.material) {
          const material = mesh.material;
          const cutData = this.materialsCutData?.find( item => mesh.name === item.name );

          if (cutData) {
            material.customProgramCacheKey = () => mode === 'main' ? this.materialsData[mesh.name].cacheKeyPrimary : this.materialsData[mesh.name].cacheKeySecondary;
            material.onBeforeCompile = e => {
              e.vertexShader = this.vertexShader;
              e.fragmentShader = mode === 'main' ? this.fragmentShader : this.fragmentShaderCut;

              /* set uniforms */
              const axisValue = cutData.axis;
              const thresholdValue = cutData.threshold;
              const directionValue = cutData.direction;

              if (!e.uniforms.uCutAxis) {
                e.uniforms.uCutAxis = { value: axisValue }
              } else {
                e.uniforms.uCutAxis.value = axisValue;
              }

              if (!e.uniforms.uCutThreshold) {
                e.uniforms.uCutThreshold = { value: thresholdValue }
              } else {
                e.uniforms.uCutThreshold.value = thresholdValue;
              }

              if (!e.uniforms.uCutDirection) {
                e.uniforms.uCutDirection = { value: directionValue }
              } else {
                e.uniforms.uCutDirection.value = directionValue;
              }
            }

            material.needsUpdate = true;
          }
        }
      });
    },
    /*
      создать прямоугольник, чтобы закрыть просветы в пачке, сквозь которые виден фон страницы
    */
    createPlane() {
      if (!this.planeData) return;
      const planeGeometry = new THREE.PlaneGeometry(this.planeData.width, this.planeData.height);
      const planeMaterial = new THREE.MeshBasicMaterial({ color: this.planeData.color, side: THREE.DoubleSide });
      const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
      planeMesh.rotation.y = - Math.PI / 2;
      planeMesh.position.set(this.planeData.x, this.planeData.y, this.planeData.z);
      this.vectaryApi.model.add(planeMesh);
    },
    handleModelLoadError() {
      this.modelLoadFailed = true;
      setTimeout(this.showPlaceholder.bind(this), 50);
    },
    showPlaceholder() {
      this.placeholderVisible = true;
    },
    rotateCameraAroundModel(step) {
      const currentState = this.vectaryApi.getViewState();
      currentState.angle += step;
      this.vectaryApi.setCurrentViewState(
        currentState,
        { updatedRotation: true }
      );
      this.requestApplyViewStateOnTick();
    },
    requestRender() {
      this.renderRequested = true;
    },
    renderScene() {
      this.renderer.render(this.scene, this.camera);
    },
    requestCanvasResize(heightFrom, heightTo) {
      if (heightFrom === heightTo) return;
      VctrApi.Utils.animate(
        'canvasResize',
        1000,
        "easeInOutQuad",
        async (timeFraction) => {
          const height = VctrApi.Utils.lerpTwoValues(heightFrom, heightTo, timeFraction);
          this.camera.aspect = this.width / height;
          this.camera.updateProjectionMatrix();
          this.renderer.setSize(this.width, height);
        },
        this.handleAnimationFinish
      );
    },
    animate() {
      VctrApi.Animator.tick();
      if (this.applyViewStateOnTickRequested) {
        this.vectaryApi.applyViewState( this.vectaryApi.getViewState() );
      }
      if (this.renderRequested) {
        this.renderScene();
      }
    },
    requestApplyViewStateOnTick() {
      this.applyViewStateOnTickRequested = true;
    },
    dismissApplyViewStateOnTick() {
      this.applyViewStateOnTickRequested = false;
    },
    recalculateStatePositions() {
      this.states.forEach(state => {
        if (state.cameraProximityRatio) {
          state.position = [
            this.adjustValueByRatio(state.position[0], state.target[0], state.cameraProximityRatio),
            this.adjustValueByRatio(state.position[1], state.target[1], state.cameraProximityRatio),
            this.adjustValueByRatio(state.position[2], state.target[2], state.cameraProximityRatio)
          ]
        }
      });
    },
    adjustValueByRatio(value1, value0, ratio) {
      const delta = value1 - value0;
      return delta * ratio + value0;
    },
    createTexture(r, g, b) {
      const width = 2048;
      const height = 2048;

      // Create an array for the texture data (RGBA)
      const size = width * height;
      const data = new Uint8Array(4 * size); // 4 channels (R, G, B, A)

      // Fill the array with red color (255, 0, 0) and full opacity (255)
      for (let i = 0; i < size; i++) {
        const stride = i * 4;
        data[stride] = r;   // Red 174
        data[stride + 1] = g; // Green 178
        data[stride + 2] = b; // Blue 182
        data[stride + 3] = 255; // Alpha
      }

      // Create the DataTexture
      const texture = new THREE.DataTexture(data, width, height);
      texture.needsUpdate = true; // Mark the texture as needing an update

      return texture;
    }
  },
  computed: {
    modelPreloaderVisible() {
      return !this.allAssetsLoaded && !this.assetLoadFailed
    },
    allAssetsLoaded() {
      return this.modelLoaded && this.environmentLoaded && this.lightMapLoaded && this.innerTextureLoadResponceReceived
    },
    assetLoadFailed() {
      return this.modelLoadFailed || this.environmentLoadFailed || this.lightMapLoadFailed
    }
  }
}
