var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-page"},[_c('div',{staticClass:"left-text"},[_c('div',{staticClass:"left-text__back",style:({
        height: _vm.textBackgroundHeight + 'px',
      })},[_c('div',{staticClass:"left-text__parallax",style:({
          height: 100 * _vm.offsets.length + 'vh',
          transform: _vm.parallaxTransform2,
        })})]),_c('div',{staticClass:"left-text__text"})]),_c('div',{staticClass:"parallax-bg",style:({
      height: 100 * _vm.offsets.length + '%',
      transform: _vm.parallaxTransform,
    })}),(_vm.activeSection != _vm.offsets.length - 1)?_c('div',{staticClass:"sections-next",style:({
      marginBottom: _vm.textHeight + 'px',
    }),on:{"click":function($event){return _vm.scrollToSection(_vm.activeSection + 1)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"8","viewBox":"0 0 11 8","fill":"none"}},[_c('path',{attrs:{"d":"M0.788574 0.884466L5.50011 5.86523L10.2117 0.884466","stroke":"#20438C","stroke-width":"2"}})])]):_vm._e(),(_vm.activeSection != 0)?_c('div',{staticClass:"sections-prev",on:{"click":function($event){return _vm.scrollToSection(_vm.activeSection - 1)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"8","viewBox":"0 0 11 8","fill":"none"}},[_c('path',{attrs:{"d":"M10.2114 6.78839L5.49989 1.80762L0.788349 6.78839","stroke":"#20438C","stroke-width":"2"}})])]):_vm._e(),_c('div',{staticClass:"sections-menu",style:({
      marginTop: -(_vm.textHeight - _vm.headerHeight) / 2 + 'px',
    })},_vm._l((_vm.offsets),function(offset,index){return _c('span',{key:index,staticClass:"menu-point",class:{ active: _vm.activeSection == index },on:{"click":function($event){return _vm.scrollToSection(index)}}})}),0),_c('div',{ref:"packOverlay",staticClass:"cigarettes-pack-page cigarettes-pack-page-4",class:{'mobile-rotate': _vm.mobileRotate}},[_c('canvas',{ref:"canvas3D",staticClass:"canvas-3d",class:{'canvas-3d_hidden': !_vm.allAssetsLoaded}}),(_vm.assetLoadFailed)?_c('div',{staticClass:"cigarettes-pack-page__placeholder cigarettes-pack-page__placeholder_4",class:{'cigarettes-pack-page__placeholder_hidden': !_vm.placeholderVisible}}):_vm._e(),_c('div',{staticClass:"canvas-3d__loader",class:{'canvas-3d__loader_hidden': !_vm.modelPreloaderVisible}},[_c('img',{attrs:{"src":"/frontend/assets/i/loader.svg","alt":""}})]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show360)?_c('div',{staticClass:"cigarettes-pack-page__360"}):_vm._e()])],1),_c('div',{style:({
      height: _vm.containerHeight + 'px',
    }),attrs:{"id":"fp"}},_vm._l((_vm.$slots),function(item,key,index){return _c('section',{key:index,staticClass:"fullpage",class:{
        active: _vm.animateIndex === index,
        transition: _vm.slideTransitionRunning,
        leaving: _vm.animateLeaveIndex === index,
        'has-button': _vm.nextButton.url && _vm.offsets.length === index + 1
      }},[_c('div',[_c('transition',{attrs:{"name":"slide-left"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.animateIndex === index),expression:"animateIndex === index"}],ref:"anim",refInFor:true,staticClass:"animate-block"},[_vm._t(key)],2)]),(_vm.nextButton.url && _vm.offsets.length == index + 1)?_c('div',{staticClass:"_g-hidden-tablet"},[_c('div',{staticClass:"root-more _product"},[_c('div',{staticClass:"container"},[_c('transition',{attrs:{"name":"slide-right"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.animateIndex == index),expression:"animateIndex == index"}]},[_c('router-link',{staticClass:"button button__lg button__blue button__arrow",attrs:{"to":{ path: _vm.nextButton.url }},domProps:{"innerHTML":_vm._s(_vm.nextButton.text)}})],1)])],1)])]):_vm._e()],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }