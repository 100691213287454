<template>
  <div>
    <template v-if="changeType === 'email'">
      <div v-if="step === 1">
        <h2 class="form__title">Редактировать электронную почту</h2>
        <div v-if="pendingConfirmEmail.length"
             class="form__description">
          Электронный адрес, ожидающий подтверждения: {{ pendingConfirmEmail }}
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
              label="Новая электронная почта*"
              v-model="$v.email.$model"
              @input="error = null"
              name="email"
              :error="$v.email.$error || error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.email.$error || error">
                <div v-if="!$v.email.required">
                  Обязательное поле
                </div>
                <div v-else-if="!$v.email.email">
                  Неверный формат электронной почты
                </div>
                <div v-else-if="error">
                  Эта электронная почта уже используется. Укажите другой адрес электронной почты.
                </div>
              </div>
            </template>
          </text-input>
          <div class="form__footnote">*Обязательное поле</div>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="sendEmail">Сохранить</button>
        </div>
        <div class="form-row form-row_align-center">
          <a href="#" class="form__link" @click.prevent="returnToProfile">Назад</a>
        </div>
      </div>
      <div v-if="step === 2">
        <div class="form-row form-row_align-center">
          <p>
            Письмо отправлено на <span class="form__link">{{ email }}</span> <br>
            Для подтверждения смены электронного адреса перейдите, пожалуйста, по ссылке из письма
          </p>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="returnToProfile">Вернуться
          </button>
        </div>
      </div>
    </template>
    <template v-if="changeType === 'phone'">
      <div v-if="step === 1">
        <h2 class="form__title">Редактировать номер телефона</h2>
        <div v-if="pendingConfirmPhone.length" class="form__description">
          Телефон, ожидающий подтверждения: +{{ pendingConfirmPhone }}
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
              label="Номер телефона*"
              v-model="$v.phone.$model"
              @input="error = null"
              name="phone"
              type="tel"
              :error="$v.phone.$error || error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.phone.$error || error">
                <div v-if="!$v.phone.required">
                  Обязательное поле
                </div>
                <div v-else-if="!$v.phone.phoneRegex">
                  Неверный формат телефона
                </div>
                <div v-else-if="error">
                  Этот телефон уже используется. Укажите другой номер.
                </div>
              </div>
            </template>
          </text-input>
          <div class="form__footnote">*Обязательное поле</div>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="sendPhone">Сохранить</button>
        </div>
        <div class="form-row form-row_align-center">
          <a href="#" class="form__link" @click.prevent="returnToProfile">Назад</a>
        </div>
      </div>
      <div v-if="step === 2 && phoneValidate">
        <h2 class="form__title">Подтверждение номера</h2>
        <div class="form__description">
          <p>
            Для подтверждения позвоните с&nbsp;номера <b class="_g-text-no-wrap">{{ phone }}</b>
            по&nbsp;номеру, указанному ниже. Звонок бесплатный.
            Подтверждение Вашего номера телефона произойдет автоматически в&nbsp;течение пяти секунд после звонка.
          </p>

          <h2>
            <a class="form__link" :href="`tel:+${phoneValidate.data.callDetails.confirmationNumber}`">
              {{ phoneValidate.data.callDetails.confirmationNumber | VMask('+# (###) ###-##-##') }}
            </a>
          </h2>
        </div>

        <div class="form-row form-row_mobile-wrap form__actions">
          <div class="form__nav">
            <p>
              Если звонок не проходит, вы можете <br> запросить другой номер для&nbsp;звонка
              <span v-if="resendTimeLeft > 0">через&nbsp;<b>{{ resendTimeLeft }}&nbsp;сек</b></span>
            </p>
          </div>
          <button class="form__submit button button__lg button__blue"
                  type="submit"
                  :disabled="isLoading || resendTimeLeft > 0"
                  @click="changePhone"
          >
            запросить заново
          </button>
        </div>

        <div class="form-row form-row_mobile-wrap">
          <div class="form__nav">
            <a href="#" class="form__link" @click.prevent="toFirstStep">Изменить номер</a>
          </div>
          <div class="form__nav">
            <a href="#" class="form__link" @click.prevent="returnToProfile">Вернуться в личный кабинет</a>
          </div>
        </div>
      </div>
      <div v-if="step === 3">
        <div class="form-row form-row_align-center">
          <p>
            Ваш телефон успешно обновлён
          </p>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="returnToProfile">Вернуться
          </button>
        </div>
      </div>
    </template>
    <template v-if="changeType === 'address'">
      <div v-if="step === 1">
        <h2 class="form__title">Редактировать регион</h2>


        <div class="form-row form-row_no-flex">
          <dadata-custom
            label="Регион*"
            v-model="address.customerRegion"
            :error="$v.address.customerRegion.$error"
            from-bound="region"
            to-bound="region"
          >
            <template #errors>
              <div class="dadata-input__error" v-if="$v.address.customerRegion.$error">
                <div v-if="!$v.address.customerRegion.required">Выберите вариант из выпадающего меню</div>
              </div>
            </template>
          </dadata-custom>
          <div class="form__footnote">*Обязательное поле</div>
        </div>

        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="sendAddress">Сохранить</button>
        </div>

        <div class="form-row form-row_align-center ajax-status ajax-status_fail" v-if="error">
          {{ error }}
        </div>
        <div class="form-row form-row_align-center ajax-status ajax-status_success" v-if="successMessage">
          {{ successMessage }}
        </div>
        <div class="form-row form-row_align-center">
          <a href="#" class="form__link" @click.prevent="returnToProfile">Назад</a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {required, email, helpers} from "vuelidate/lib/validators"
import api from "../../services/api"
import { debounce } from "lodash"
import { nextTick } from "vue"

const phoneRegex = helpers.regex('val1', /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ -]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/);
const REFRESH_TIMEOUT = 60

export default {
  name: "ProfileCredentialsChange",
  props: {
    changeType: {
      type: String,
      default: 'email'
    },
    profile: {
      type: Object,
    }
  },
  data() {
    return {
      email: '',
      phone: '',
      address: {
        fiasId: '',
        customerRegion: '',
      },
      step: 1,
      resendTimeLeft: 0,
      phoneValidate: null,
      checkInterval: true,
      error: null,
      successMessage: null,
    }
  },
  validations: {
    email: {required, email},
    phone: {required, phoneRegex},
    address: {
      fiasId: {required},
      customerRegion: {required},
    }
  },
  created() {
    /*
      copy necessary fields from this.profile.customFields object to this.address object
    */
    if (this.changeType === 'address') {
      this.copyAddressData();
    }
  },
  mounted() {
    if (this.pendingConfirmEmail.length) {
      this.email = this.pendingConfirmEmail;
    }
    if (this.pendingConfirmPhone.length) {
      this.phone = String(this.pendingConfirmPhone).slice(1);
    }
    if (this.$route.params && this.$route.params.email) {
      this.email = this.$route.params.email
      this.sendEmail()
      this.$route.params.email = ''
    }
  },
  watch: {
    /*
      copied from ProfileForm.vue
    */
    fullAddress(val) {
      this.getAddressInfo(val);
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    clearedPhone() {
      return String(this.phone.replace(/\D/g, ""));
    },
    pendingConfirmPhone() {
      if (!this.profile.isMobilePhoneConfirmed && this.profile.pendingMobilePhone) {
        return this.profile.pendingMobilePhone;
      }
      return '';
    },
    pendingConfirmEmail() {
      if (!this.profile.isEmailConfirmed && this.profile.pendingEmail) {
        return this.profile.pendingEmail;
      }
      return '';
    },
    /*
      copied from ProfileForm.vue
    */
    fullAddress() {
      return `${this.address.customerRegion}`
    },
  },
  methods: {
    sendEmail() {
      this.$v.email.$touch();
      if (!this.$v.email.$pending && !this.$v.email.$invalid) {
        this.changeEmail();
      }
    },
    sendPhone() {
      this.$v.phone.$touch();
      if (!this.$v.phone.$pending && !this.$v.phone.$invalid) {
        if (this.clearedPhone == this.profile.mobilePhone && this.clearedPhone != this.pendingConfirmPhone) {
          this.error = 'Этот телефон уже используется. Укажите другой номер.'
          return;
        }
        this.changePhone();
      }
    },
    sendAddress() {
      this.$v.address.$touch();
      if (!this.$v.address.$pending && !this.$v.address.$invalid) {
        this.changeAddress();
      }
    },
    async changeEmail() {
      this.$store.dispatch('showLoader');

      try {
        await api.changeEmail(this.email);
        this.step += 1;
      } catch (e) {
        this.error = e.errors[0].message;
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async changePhone() {
      if (this.resendTimeLeft > 0) {
        return;
      }

      this.$store.dispatch('showLoader');
      this.error = null;

      try {
        this.phoneValidate = await api.verifyPhoneByCall(this.clearedPhone);
        this.startResendInterval()
        this.step = 2;
      } catch (e) {
        this.error = e.errors[0].message;
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async changeAddress() {
      this.$store.dispatch('showLoader');
      const result = await api.updateAddress({
        fiasId: this.address.fiasId,
        //customerStreet: this.address.customerStreet,
        //customerCity: this.address.customerCity,
        //customerHouse: this.address.customerHouse,
        //customerIndex: this.address.customerIndex,
        //customerFlat: this.address.customerFlat,
        personalDataAgreementSignature: this.profile.customFields.personalDataAgreementSignature
      });
      this.$store.dispatch('hideLoader');
      if (result) {
        this.error = null;
        this.successMessage = 'Адрес успешно обновлен';
      } else {
        this.error = 'Ошибка сохранения адреса';
        this.successMessage = null;
      }
    },
    async callConfirmationCheck() {
      try {
        if (this.resendTimeLeft % 5 === 0) {
          const callId = await api.verifyPhoneByCallCheck(this.phoneValidate?.data?.callDetails?.callId);
          if (callId) {
            this.stopResendInterval();
            this.step = 3;
            this.error = null;
          }
        }

        if (this.resendTimeLeft > 0) {
          this.resendTimeLeft--;
        } else {
          this.stopResendInterval();
        }
      } catch (e) {
        this.step = 1;
        this.$store.dispatch('setErrors', e);
      }
    },
    startResendInterval() {
      this.stopResendInterval();
      this.resendTimeLeft = REFRESH_TIMEOUT;
      this.checkInterval = setInterval(this.callConfirmationCheck, 1000);
    },
    stopResendInterval() {
      this.resendTimeLeft = 0;
      clearInterval(this.checkInterval);
    },
    toFirstStep() {
      this.step = 1;
      this.stopResendInterval();
    },
    returnToProfile() {
      this.$emit('returnToProfile');
    },
    copyAddressData() {
      const addressFieldnames = ['customerRegion'];
      addressFieldnames.forEach((fieldname) => {
        if (this.profile.customFields[fieldname]) {
          this.address[fieldname] = this.profile.customFields[fieldname];
        }
      });
    },
    /*
      copied from ProfileForm.vue
    */
    getAddressInfo: debounce(async function(val) {
      try {
          const { data = {} } = await api.DADATA.getAddress({
            data: {
              query: val,
              count: 1,
              restrict_value: true,
            },
          });
          this.address.fiasId = data.suggestions.length? data.suggestions[0].data.fias_id: '';
        } catch (e) {
          console.log(e);
        }
    }, 1),
    handleCustomerFlatInput() {
      this.filterOutDigits(this.address, 'customerFlat');
    },
    handleCustomerIndexInput() {
      this.filterOutDigits(this.address, 'customerIndex');
    },
    async filterOutDigits(object, variableName) {
      const newValue = object[variableName].replace(/[^0-9]/g, '');
      await nextTick();
      object[variableName] = newValue;
    },
  },
  beforeDestroy() {
    this.stopResendInterval();
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/base/_settings.scss";

.form-row {
  > div {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 16px;
      }
    }
  }
}

.form__actions {
  gap: 10px;
  @include mobile {
    text-align: center;
  }
}

.form__submit {
  white-space: nowrap;
}

.ajax-status {
  font-size: 11px;
  line-height: 1.4;

  &_fail {  
    color: $error-text;
  }

  &_success {
    color: $true-color;
  }
}
</style>
