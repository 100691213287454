import {VctrApi} from "../scripts-3d/vectaryToThreeJs";
export default {
  props: {
    crushMode: {
      type: Boolean,
      default: false,
    },
    nextButton: {
      type: Object,
      default: () => {
        return { url: '', text: '' }
      }
    }
  },
  data: function () {
    return {
      vectaryApi: null,
      isClosed: true, // индикатор закрытой пачки
      isUp: false, // индикатор "вытащенной" сигареты
      inMove: false,
      activeSection: 0,
      previousSection: 100,
      animateLeaveIndex: 100,
      slideTransitionRunning: false,
      offsets: [],
      sectionsData: [],
      maxCanvasSize: null,
      touchStartY: 0,
      parallaxTransform: "",
      parallaxTransform2: "",
      animateIndex: 0,
      textHeight: 0,
      textBackgroundHeight: 0,
      containerHeight: 480,
      isScrolling: null,
      show360: true,
      mobileRotate: false,
      productPage: null,
      windowMode: null,
      heightCollapsed: 0,
      heightExpanded: 0,
      heightDefault: null,
      scrolledOnce: false,
      savedMeasurements: {},
      headerHeight: null,
      footerHeight: null,
      slideChangeLabel: null, // e.g. 02 - moving from slide 0 to slide 2
      /*
        define which animation type unblocks slides changing
        example: moveCigarette - 02
        when moving from slide 0 to slide 2, unblock slides changing after 'moveCigarette' animation is finished
      */
      animationUnblockingTypes: {
        'animatePackPosition': ['04', '12', '13', '21', '23', '31', '32', '40'],
        'switchPack': ['10', '14', '20', '24', '30', '34'],
        'moveCigarette': ['01', '02', '03', '41', '42', '43']
      }
    };
  },
  async mounted() {
    this.productPage = document.querySelector('.product-page');
    document.getElementById("fp").addEventListener('wheel', this.handleMouseWheelDOM, { passive: false });  // Mozilla Firefox
    document.getElementById("fp").addEventListener("mousewheel", this.handleMouseWheel, { passive: false,}); // Other browsers
    document.getElementById("fp").addEventListener("touchstart", this.touchStart, { passive: false }); // mobile devices
    document.getElementById("fp").addEventListener("touchmove", this.touchMove, { passive: false }); // mobile devices
    if (this.crushMode) {
      document.querySelector("body").classList.add("product-crush");
    }
    window.addEventListener('resize', this.resizeEvent);

    this.vectaryApi= new VctrApi("cigarettes-pack-page__model");
    this.vectaryApi.setProductPage(this);

    setTimeout(()=> {
      this.show360 = false;
    }, 20000)

    //this.resizeEvent();
  },
  beforeDestroy() {
    this.vectaryApi = null;
    document.querySelector("body").classList.remove("product-crush");
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    /*
      calculate and store necessary data
      should be called on window resize only
    */
    calculateSectionOffsets() {
      let sections = document.getElementsByTagName("section");
      let length = sections.length;
      this.offsets = [];
      this.sectionsData = [];
      for (let i = 0; i < length; i++) {
        const textHeight = this.$refs.anim[i].offsetHeight;
        const { viewportHeight, fullpageHeight } = this.calculateViewportAndFullpageHeight(textHeight);
        const canvasData = this.calculateCanvasSize(this.packContainerWidth, viewportHeight);
        const data = { textHeight, viewportHeight, canvasData, fullpageHeight };
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
        this.sectionsData.push(data);
      }
      this.maxCanvasSize = this.getMaxCanvasSize(this.sectionsData);

      // calculate canvas scale for each section
      this.sectionsData.forEach(sectionData => {
        sectionData.canvasScale = sectionData.canvasData.height / this.maxCanvasSize.canvasData.height;
      });
    },
    getMaxCanvasSize(list) {
      let maxHeight = 0;
      let id;
      list.forEach((data, index) => {
        const canvasData = data.canvasData;
        if (canvasData.height > maxHeight) {
          maxHeight = canvasData.height;
          id = index;
        }
      });
      return list[id];
    },
    getWindowWidth() {
      if (window.visualViewport) {
        return window.visualViewport.width; // floating-point value (needed if browser window is zoomed)
      } else {
        return window.innerWidth
      }
    },
    calculateMobileRotateHeight() {
      this.savedMeasurements = {
        heightCollapsed: this.heightCollapsed,
        heightExpanded: this.heightExpanded,
        windowMode: this.windowMode,
        mobileRotate: this.mobileRotate
      }
      const windowWidth = this.getWindowWidth();
      if (windowWidth <= 767) {
        this.windowMode = 'mobile';
      } else if (windowWidth <= 1023) {
        this.windowMode = 'tablet';
      } else {
        this.windowMode = 'desktop';
      }

      const productPageHeight = this.productPage.offsetHeight;
      // mimicking 'height: calc(100vh - 134px)'
      this.heightDefault = window.innerHeight - 134;
      // mimicking max-height styles
      if (this.windowMode != 'desktop') {
        this.heightCollapsed = this.capMax(
          this.windowMode === 'mobile' ? 280 : productPageHeight * .59,
          this.heightDefault
        );
        this.heightExpanded = this.capMax(
          this.windowMode === 'mobile' ? productPageHeight * .7 : productPageHeight * .74,
          this.heightDefault
        );
      } else {
        this.heightCollapsed = this.heightExpanded = this.heightDefault;
      }
    },
    capMax(value, maxValue) {
      if (value > maxValue) {
        return maxValue;
      } else {
        return value;
      }
    },
    onScrollEnd() {
      let t = this;
      window.clearTimeout(t.isScrolling);
      t.inMove = true;

      this.isScrolling = setTimeout(function () {
        t.inMove = false;
      }, 1000);
    },
    handleMouseWheel: function (e) {
      var isOverflowSection =
        document.getElementsByTagName("section")[this.activeSection]
          .offsetHeight >
        window.innerHeight - this.footerHeight;
      var innerOffset = document
        .getElementsByTagName("section")[this.activeSection].getBoundingClientRect().top;
      if (e.wheelDelta < 0) {
        if (
          isOverflowSection &&
          window.innerHeight < document.getElementById('fp').clientHeight - window.pageYOffset
        ) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveUp();
          //e.preventDefault();
          return false;
        }
      } else if (e.wheelDelta > 0) {
        if (isOverflowSection && Math.round(innerOffset) !== 0) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveDown();
          //e.preventDefault();
          return false;
        }
      }
      //e.preventDefault();
      return false;
    },
    handleMouseWheelDOM: function (e) {
      var isOverflowSection =
        document.getElementsByTagName("section")[this.activeSection]
          .offsetHeight >
        window.innerHeight - this.footerHeight;
      var innerOffset = document
        .getElementsByTagName("section")[this.activeSection].getBoundingClientRect().top;
      if (e.deltaY > 0) {
        if (
          isOverflowSection &&
          window.innerHeight < document.getElementById('fp').clientHeight - window.pageYOffset
        ) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveUp();
          //e.preventDefault();
          return false;
        }
      } else if (e.deltaY < 0) {
        if (isOverflowSection && Math.round(innerOffset) !== 0) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveDown();
          //e.preventDefault();
          return false;
        }
      }
      return false;
    },
    async animatePackPosition(toState, duration = 1000) {
      const currentState = await this.vectaryApi.getViewState();
      VctrApi.Utils.animate(
        'animatePackPosition',
        duration,
        "easeInOutQuad",
        async (timeFraction) => {
          if (this.vectaryApi) {
            const angle = VctrApi.Utils.lerpTwoValues(currentState.angle, toState.angle, timeFraction);
            const distance = VctrApi.Utils.lerpTwoValues(currentState.distance, toState.distance, timeFraction);
            const position = VctrApi.Utils.lerp(currentState.position, toState.position, timeFraction);
            const target = VctrApi.Utils.lerp(currentState.target, toState.target, timeFraction);
            const zoom = VctrApi.Utils.lerpTwoValues(currentState.zoom, toState.zoom, timeFraction);
            const state = { target, zoom, angle, distance, position };

            this.vectaryApi.setCurrentViewState(
              state,
              { updatedRotation: true }
            );
            this.requestApplyViewStateOnTick();
          }
        },
        this.handleAnimationFinish
      );
    },
    handleAnimationFinish(label) {
      // handle initial animation finish
      if (label === 'canvasResize' && !this.slideChangeLabel) {
        this.inMove = false;
        return;
      }
      for (let p in this.animationUnblockingTypes) {
        const list = this.animationUnblockingTypes[p];
        if (p === label && list.includes(this.slideChangeLabel)) {
          this.inMove = false; // unblocking ability to change slides
          return;
        }
      }
    },
    moveDown() {
      this.inMove = true;
      this.activeSection--;

      if (this.activeSection < 0) {
        this.activeSection = 0;
        this.inMove = false;
        return;
      }

      this.animateIndex = null;

      this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection++;

      if (this.activeSection > this.offsets.length - 1) {
        this.activeSection = this.offsets.length - 1;
        this.inMove = false;
        return;
      }
      this.animateIndex = null;

      this.scrollToSection(this.activeSection, true);
    },
    /*
      viewport size is different for each slide because text amount for each slide is different
    */
    calculateViewportAndFullpageHeight(textHeight) {
      let viewportHeight, fullpageHeight; // fullpageHeight - height of an element #fp which contains .fullpage elements
      if (this.windowWidth <= 1023) { // 3D-viewport above the text
        const textMarginTop = this.windowWidth <= 767 ? 290 : 514; // values copied from CSS
        const textWithMarginHeight = textHeight + textMarginTop;
        const additionalHeight = textWithMarginHeight < this.windowHeight ? this.windowHeight - textWithMarginHeight : 0;
        const topAreaHeight = textMarginTop + additionalHeight;
        viewportHeight = topAreaHeight - this.headerHeight;
        fullpageHeight = textWithMarginHeight + additionalHeight;
      } else { // 3D-viewport to the left of the text
        const footerHeight = 35; // TODO: define footerHeight outside of this function among other measurements
        viewportHeight = this.windowHeight - this.headerHeight - footerHeight;
        fullpageHeight = this.windowHeight;
      }
      return { viewportHeight, fullpageHeight };
    },
    scrollToSection(id, force = false, performPackAnimation = true) {
      if (id === this.activeSection && !force) return;

      if (this.show360 && id !== 0) {
        this.show360 = false;
      }
      if (this.inMove && !force) return false;

      if (this.activeSection !== id) {
        this.previousSection = this.activeSection;
        this.activeSection = id;
      }

      this.canvas.style.transform = `translate(-50%, -50%) scale(${this.sectionsData[id].canvasScale})`;

      /*
        TODO: remove setTimeout() from all animations since it leads to errors
        implement delay property instead
      */
      setTimeout(() => {
        this.animateIndex = id;
        this.animateLeaveIndex = this.previousSection;
        this.slideTransitionRunning = true;
        this.parallaxTransform = "translate3d(0px," + (this.activeSection * -40) / this.offsets.length + "%, 0px)";
        this.parallaxTransform2 = "translate3d(0px," + (this.activeSection * -60) / this.offsets.length +"%, 0px)";

        this.$nextTick(() => {
          document.getElementById("fp").scroll({
            top: this.offsets[id],
            behavior: "smooth",
          });
          this.containerHeight = this.sectionsData[id].fullpageHeight;


          this.textHeight = this.$refs.anim[id].offsetHeight;
          this.textBackgroundHeight = this.windowWidth <= 1023 ? this.textHeight : this.windowHeight;

          const viewportHeight = this.sectionsData[id].viewportHeight;
          this.rotator.receiveHeight(this.sectionsData[id].canvasData.height);
          this.packOverlay.style = `height: ${viewportHeight}px; max-height: unset; transition: height .7s`;

          if (performPackAnimation) {
            this.inMove = true;
            this.animatePack();
          }
        });
      }, 5);

      // change 'fullpage' class to 'fullpage active when'
      setTimeout(() => {
        this.slideTransitionRunning = false;
        this.animateLeaveIndex = null;
      }, 505);
    },
    touchStart(e) {
      this.touchStartY = e.touches[0].clientY;
    },
    touchMove(e) {
      if (this.inMove) return false;

      var isOverflowSection = document.getElementsByTagName("section")[this.activeSection].offsetHeight > window.innerHeight - this.footerHeight;
      var innerOffset = document.getElementsByTagName("section")[this.activeSection].getBoundingClientRect().top;

      const currentY = e.touches[0].clientY;

      if (this.touchStartY < currentY) {
        if (isOverflowSection && Math.round(innerOffset) !== 0) {
          this.onScrollEnd();
          return;
        }
        //e.preventDefault();
        this.moveDown();
      } else {
        if (
          isOverflowSection &&
          window.innerHeight < Math.floor(document.getElementById('fp').clientHeight - window.pageYOffset)
        ) {
          this.onScrollEnd();
          return;
        }
        //e.preventDefault();
        this.moveUp();
      }
      this.touchStartY = 0;
      return false;
    },
    resizeEvent() {
      this.textHeight = this.$refs.anim[this.activeSection].offsetHeight;
      this.packContainerWidth = this.packOverlay.offsetWidth;
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.headerHeight = document.querySelector(".header").offsetHeight;
      this.footerHeight = document.querySelector(".footer").offsetHeight;
      this.canvasHeight = this.$refs.canvas3D.offsetHeight;
      this.calculateSectionOffsets();

      const canvasWidth = this.maxCanvasSize.canvasData.width;
      const canvasHeight = this.maxCanvasSize.canvasData.height;

      this.cameraWidth = canvasWidth;
      this.cameraHeight = canvasHeight;
      this.camera.aspect = this.cameraWidth / this.cameraHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(canvasWidth, canvasHeight);

      this.renderScene();

      this.scrollToSection(this.activeSection, true, false);
    },
    // if canvas height is changed by css it should be changed within renderer and camera as well
    checkCanvasHeightChange() {
      const oldHeight =
      this.savedMeasurements.mobileRotate ?
      this.savedMeasurements.heightExpanded :
      this.savedMeasurements.heightCollapsed;
      const newHeight = this.mobileRotate ? this.heightExpanded : this.heightCollapsed;
      if (oldHeight !== newHeight) {
        this.requestCanvasResize(oldHeight, newHeight);
      }
    },
    showFullpage(index) {
      return ((this.windowWidth > 1023) && (this.animateIndex !== index)) ? false : true
    },
    activateMobileRotate() {
      this.mobileRotate = true;
    },
    deactivateMobileRotate() {
      this.mobileRotate = false;
    }
  },
  watch: {
    mobileRotate(newValue) {
      if (!this.scrolledOnce) {
        this.scrolledOnce = true;
        this.resizeEvent();
      }
      if (this.windowMode === 'desktop') return;
      if (newValue === true) {
        this.requestCanvasResize(this.heightCollapsed, this.heightExpanded);
      } else {
        this.requestCanvasResize(this.heightExpanded, this.heightCollapsed);
      }
    },
    activeSection(newValue, oldValue) {
      this.slideChangeLabel = String(oldValue) + String(newValue);
    }
  }
}
